import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";


function DiscordCallback() {
    const location = useLocation();
    const navigate = useNavigate();

    React.useEffect(() => {
        const searchParam = new URLSearchParams(location.search);
        const code = searchParam.get("code")
        const error = searchParam.get('error')

        const handleDiscordLogin = async (code) => {
            console.log(code);
            try {
                fetch('https://api.gdscout.me/api/discord/login/',  {
                    headers: {
                        'Authorization': `Bearer ${code}`
                    }
                })
                .then(reponse => reponse.json())
                .then(result => {
                    const access_token = result['access_token']
                    if(access_token != null) {
                        localStorage.setItem('last_auth', Date.now())
                        localStorage.setItem('auth_token', access_token)
                        navigate("/scout", { replace: true })
                    }
                })
            } catch (error) {
                console.log(error)
            }
        }

        if(code) {
            handleDiscordLogin(code) 
        } else if(error) {
            console.error("Login Failed...", error)
        }
    })

    return (
        <>
            <div className="text-yellow-400">
                Redirecting...
            </div>
        </>
    );
}

export default DiscordCallback;