import { useGoogleLogin } from "@react-oauth/google";
import { useEffect } from "react";
import { redirect, useNavigate } from "react-router-dom";
import { DiscordLoginButton, GoogleLoginButton } from "react-social-login-buttons";

export default function Login() {

    const navigate = useNavigate();

    useEffect(() => {
        if(localStorage.getItem('auth_token') != null) {
            navigate('/scout')
        }
    })

    const googleLogin = useGoogleLogin({
        flow: 'auth-code',
        ux_mode: 'redirect',
        redirect_uri: "https://gdscout.me/google/auth/",
    })

    const discordLogin = () => {
        window.location.href = "https://discord.com/api/oauth2/authorize?client_id=1194708320309751919&response_type=code&redirect_uri=https%3A%2F%2Fgdscout.me%2Fdiscord%2Fauth%2F&scope=identify+guilds+guilds.members.read"
    }

    return (
        <>
            <div>
            </div>
            <main className="text-white">
                {/* Discord Login */}
                <section className="flex justify-center items-center h-screen">
                    <div className="loginForm">
                        {/* Logo */}
                            <div className="info flex flex-col items-center">
                                <img src="https://github.com/GoldenDragons772.png" alt="GDScout Logo" className="w-60 h-60" />
                                <h1 className="text-4xl font-bold">GDScout</h1>
                            </div>
                        <div className="mt-5">
                            <div>
                                <DiscordLoginButton onClick={discordLogin} />
                                <GoogleLoginButton onClick={() => googleLogin()} />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}