import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        fetch('https://api.gdscout.me/api/google/logout/', {
            method: 'POST'
        })
        .then((response) => {
            console.log(response)
        })
        .catch((error) => {
            if(error.response) {
                console.error(error.response)
            }
        })
        .finally(() => {
            localStorage.removeItem('auth_token')
            navigate('/login')
        })
    })
}

export default Logout