import { Dialog, Transition } from "@headlessui/react";
import { CircularProgress } from "@mui/material";
import React, { Fragment, useState } from "react";

function PitScout() {

  // confirm Submit
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function closeModal() {
    if (!isSubmitting) {
      setIsOpen(false);
      setIsSubmitting(false);
    }
  }

  function openModal() {
    setIsOpen(true);
  }

    const [teamNum, seTeamNum] = useState("");
    const [teamName, setTeamName] = useState("");
    const [robotName, setRobotName] = useState("");
    const [canHang, setCanHang] = useState(false);
    const [canBackdrop, setCanBackdrop] = useState(false);
    const [droneZone, setDroneZone] = useState(0);
    const [autoTask, setAutoTask] = useState(0);
    const [scoutNotes, setScoutNotes] = useState("");

  function clearForm() {
    seTeamNum("");
    setTeamName("");
    setRobotName("");
    setCanHang(false);
    setCanBackdrop(false);
    setDroneZone(0);
    setAutoTask(0);
    setScoutNotes("");
  }

  const addTeamInfo = () => {
    console.log("Clicked...");

    const data = {
      teamNumber: teamNum,
      teamName: teamName,
      robotName: robotName,
      canHang: canHang,
      canBackdrop: canBackdrop,
      droneZone: droneZone,
      autoTask: autoTask,
      scoutNotes: scoutNotes,
    };

    // Process of Submitting...
    setIsSubmitting(true);

    console.log(data);

    console.log(`Bearer ${localStorage.getItem("auth_token")}`);

    fetch("https://api.gdscout.me/api/update/pit/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((reponse) => {
        if (reponse.ok) {
          clearForm();
          closeModal();
          setIsSubmitting(false);
          console.log("It Works!!!");
        } else {
          console.log("Something went Wrong :/");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <form
        id="scouter"
        onSubmit={(e) => {
          e.preventDefault();
          openModal()
        }}
        className="flex mt-20  w-64 flex-col"
      >
        <div className="flex flex-col mb-2">
          <label htmlFor="teamNumber">Team Number</label>
          <input
            onChange={(e) => seTeamNum(e.target.value)}
            value={teamNum}
            id="teamNumber"
            className="text-black"
            type="text"
            required
          />
        </div>
        <div className="flex flex-col mb-2">
          <label htmlFor="teamName">Team Name</label>
          <input
            onChange={(e) => setTeamName(e.target.value)}
            value={teamName}
            id="teamName"
            className="text-black"
            type="text"
          />
        </div>
        <div className="flex flex-col mb-2">
          <label htmlFor="robotName">Robot Name</label>
          <input
            onChange={(e) => setRobotName(e.target.value)}
            value={robotName}
            className="text-black"
            id="robotName"
            type="text"
          />
        </div>
        <div className="flex flex-row space-x-1">
          <input
            value={canHang}
            onChange={() => setCanHang(!canHang)}
            type="checkbox"
            id="hang"
            name="hang"
          />
          <label htmlFor="hang">Hang</label>
          <input
            value={canBackdrop}
            onChange={() => setCanBackdrop(!canBackdrop)}
            type="checkbox"
            id="backdrop"
            name="backdrop"
          />
          <label htmlFor="backdrop">Backdrop</label>
        </div>
        <div className="actions">
          <div className="flex flex-col">
            <label id="Drone">Drone Zone</label>
            <select
              className="text-black"
              value={droneZone}
              onChange={(e) => setDroneZone(e.target.value)}
              name="Drone"
              id="Drone"
            >
              <option value={0}>None</option>
              <option value={1}>Zone 1 (30)</option>
              <option value={2}>Zone 2 (20)</option>
              <option value={3}>Zone 3 (10)</option>
            </select>
          </div>
          <div className="flex flex-col mb-2">
            <label>Auto</label>
            <select
              value={autoTask}
              onChange={(e) => setAutoTask(e.target.value)}
              className="text-black"
              name="Autonomous"
              id="Auto"
            >
              <option value={0}>None</option>
              <option value={1}>Park</option>
              <option value={2}>Park + Pixels</option>
              <option value={3}>Park + Backdrop</option>
            </select>
          </div>
        </div>
        <div className="notes flex flex-col">
          <label>Notes</label>
          <textarea
            value={scoutNotes}
            id="notes"
            onChange={(e) => setScoutNotes(e.target.value)}
            className="h-56 text-black"
            placeholder="Description"
          />
        </div>
      </form>
      <div className="fixed bottom-2 flex justify-center w-full z-30">
          <button
            type="submit"
            form="scouter"
            className="bg-blue-600 w-[95%] h-16 rounded-xl text-white"
          >
            Submit
          </button>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {isSubmitting ? "Submitting..." : "Confirm Submit"}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-700">
                      {isSubmitting
                        ? "Please wait while we submit this Pit."
                        : "Are you sure you want to submit this Pit?"}
                    </p>
                  </div>

                  <div className="mt-4 flex justify-end space-x-2">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        setIsOpen(false);
                        setIsSubmitting(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className={`inline-flex ${
                        isSubmitting ? "cursor-default" : "hover:bg-blue-200"
                      } justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900  focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2`}
                      onClick={addTeamInfo}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularProgress size={20} /> : "Submit"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default PitScout;
