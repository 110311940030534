import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Scoutform from './Pages/Scoutform';
import Login from './Pages/Login';
import GoogleCallback from './Auth/GoogleCallback';
import Logout from './Pages/Logout';
import About from './Pages/About';
import DiscordCallback from './Auth/DiscordCallback';

function App() {
  return (
    <>
      <main>
        <div className='workspace'>
          <BrowserRouter>
            <Routes>
              <Route exact path={'/'} element={<Login />} />
              <Route exact path={'/scout'} element={<Scoutform />} />
              <Route exact path={'/login'} element={<Login />} />
              <Route exact path={'/logout'} element={<Logout />} />
              <Route exact path={'/google/auth/*'} element={<GoogleCallback />} />
              <Route exact path={'/discord/auth/*'} element={<DiscordCallback />} />
              <Route exact path={'/about'} element={<About />} />
            </Routes>
          </BrowserRouter>
        </div>
      </main>
    </>
  );
}

export default App;
