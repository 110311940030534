import { Link, useNavigate } from "react-router-dom";

function About() {
    const navigate = useNavigate()
    return (
        <>
            <main className="text-yellow-400">
                Welcome to Golden Dragons Scouting Page!
                <p>ik this page looks depressing, but TRUST it will look better.</p>
                <p>
                    Click <a className="text-purple-500 underline" href="/login">here</a> to login.
                </p>
            </main>
        </>
    )
}

export default About;