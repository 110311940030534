import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CircularProgress from "@mui/material/CircularProgress";
import Counter from "./Counter";

function MatchScout() {

  // confirm Submit
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function closeModal() {
    if (!isSubmitting) {
      setIsOpen(false);
      setIsSubmitting(false);
    }
  }

  function openModal() {
    setIsOpen(true);
  }

  // Prop Locations
  const [propType] = useState(["White Pixel", "Team Prop"]);
  const [spikeLocation] = useState(["No", "Spike Mark"]);
  const [alignSpike] = useState(["No", "Backdrop"]);
  const [navigated] = useState(["No", "Backstage"]);
  const [droneZone] = useState([
    "None",
    "Zone 1 (30)",
    "Zone 2 (20)",
    "Zone 3 (10)",
  ]);
  const [endNavigate] = useState(["No", "Backstage", "Rigging"]);
  const [matchNumber, setMatchNumber] = useState("");
  const [Team1, setTeam1] = useState("");
  const [Team2, setTeam2] = useState("");
  const [autoBackstage, setAutoBackstage] = useState(0);
  const [autoBackdrop, setAutoBackdrop] = useState(0);
  const [teleopBackstage, setTeleopBackstage] = useState(0);
  const [teleopBackdrop, setTeleopBackdrop] = useState(0);
  const [teleopMosiacs, setTeleopMosiacs] = useState(0);
  const [teleopSetLine, setTeleopSetLine] = useState(0);
  const [team1Prop, setTeam1Prop] = useState("White Pixel");
  const [team2Prop, setTeam2Prop] = useState("White Pixel");
  const [team1Spike, setTeam1Spike] = useState("No");
  const [team2Spike, setTeam2Spike] = useState("No");
  const [team1AlignSpike, setTeam1AlignSpike] = useState("No");
  const [team2AlignSpike, setTeam2AlignSpike] = useState("No");
  const [team1Navigated, setTeam1Navigated] = useState("No");
  const [team2Navigated, setTeam2Navigated] = useState("No");
  const [team1DroneZone, setTeam1DroneZone] = useState("None");
  const [team2DroneZone, setTeam2DroneZone] = useState("None");
  const [team1EndNavigate, setTeam1EndNavigate] = useState("No");
  const [team2EndNavigate, setTeam2EndNavigate] = useState("No");

  function clearform() {
    setMatchNumber("");
    setTeam1("");
    setTeam2("");
    setAutoBackstage(0);
    setAutoBackdrop(0);
    setTeleopBackstage(0);
    setTeleopBackdrop(0);
    setTeleopMosiacs(0);
    setTeleopSetLine(0);
    setTeam1Prop("White Pixel");
    setTeam2Prop("White Pixel");
    setTeam1Spike("No");
    setTeam2Spike("No");
    setTeam1AlignSpike("No");
    setTeam2AlignSpike("No");
    setTeam1Navigated("No");
    setTeam2Navigated("No");
    setTeam1DroneZone("None");
    setTeam2DroneZone("None");
    setTeam1EndNavigate("No");
    setTeam2EndNavigate("No");
  }

  const addMatchInfo = () => {
    console.log("Clicked...");

    const data = {
      matchNumber: matchNumber,
      team1: Team1,
      team2: Team2,
      team1Prop: team1Prop,
      team2Prop: team2Prop,
      team1Spike: team1Spike,
      team2Spike: team2Spike,
      team1AlignSpike: team1AlignSpike,
      team2AlignSpike: team2AlignSpike,
      teamAutoBackDropPixels: autoBackdrop,
      teamAutoBackStagePixels: autoBackstage,
      team1Navigated: team1Navigated,
      team2Navigated: team2Navigated,
      TelopBackstage: teleopBackstage,
      TeleopBackdrop: teleopBackdrop,
      Mosaics: teleopMosiacs,
      setLine: teleopSetLine,
      team1DroneZone: team1DroneZone,
      team2DroneZone: team2DroneZone,
      team1EndNavigate: team1EndNavigate,
      team2EndNavigate: team2EndNavigate,
    };

    // In process of submitting
    setIsSubmitting(true);

    console.log(data);

    console.log(`Bearer ${localStorage.getItem("auth_token")}`);

    fetch("https://api.gdscout.me/api/update/match/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((reponse) => {
        if (reponse.ok) {
          console.log("It Works!!!");
          clearform();
          closeModal();
          setIsSubmitting(false);
        } else {
          console.log("Something went Wrong :/");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className=" mt-16 w-full bg-black">
      <div className="Alliance overflow-x-hidden">
        <div className="title bg-gray-500 flex rounded-md h-12 items-center justify-center">
          Alliance Info
        </div>
        <div className="robotInfo">
          <div className="dataForm flex flex-col w-[80%] justify-center ml-[10%]">
          <label htmlFor="mtNum">Match #</label>
            <input
              value={matchNumber}
              onChange={(e) => setMatchNumber(e.target.value)}
              type="text"
              id="mtNum"
              className="matchNumber! text-black"
            />
            <label htmlFor="rb1">Robot 1 Team #</label>
            <input
              value={Team1}
              onChange={(e) => setTeam1(e.target.value)}
              type="text"
              id="rb1"
              className="RobotTeam! text-black"
            />
            <label htmlFor="rb2">Robot 2 Team #</label>
            <input
              value={Team2}
              onChange={(e) => setTeam2(e.target.value)}
              type="text"
              id="rb2"
              className="RobotTeam! text-black"
            />
          </div>
        </div>
      </div>
      <div className="Autonomous mt-5">
        <div className="title bg-gray-500 flex rounded-md h-12 items-center justify-center mb-2">
          Autonomous
        </div>
        <div className="Auto-Info">
          <div className="teamProp flex justify-center w-[80%] flex-col ml-[10%]">
            <div className="teamprop1 flex justify-between">
              <label htmlFor="prop#1">
                {Team1 !== "" ? Team1 : "Robot 1"} Team Prop:{" "}
              </label>
              <select
                id="prop#1"
                className="text-black"
                onChange={(e) => setTeam1Prop(e.target.value)}
              >
                {propType.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
            </div>
            <div className="teamprop2 flex justify-between mb-2">
              <label htmlFor="prop#2">
                {" "}
                {Team2 !== "" ? Team2 : "Robot 2"} Team Prop:{" "}
              </label>
              <select
                id="prop#2"
                className="text-black"
                onChange={(e) => setTeam2Prop(e.target.value)}
              >
                {propType.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
            </div>
            <div className="spike#1 flex justify-between">
              <label htmlFor="prop#2">
                {Team1 !== "" ? Team1 : "Robot 1"} Purple Pixel:{" "}
              </label>
              <select
                id="prop#2"
                className="text-black"
                onChange={(e) => setTeam1Spike(e.target.value)}
              >
                {spikeLocation.map((value, key) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
            </div>
            <div className="spike flex justify-between mb-2" id="2">
              <label htmlFor="prop#2">
                {Team2 !== "" ? Team2 : "Robot 2"} Purple Pixel:{" "}
              </label>
              <select
                id="prop#2"
                className="text-black"
                onChange={(e) => setTeam2Spike(e.target.value)}
              >
                {spikeLocation.map((value, key) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
            </div>
            <div className="backdrop1 flex justify-between" id="1">
              <label htmlFor="prop#2">
                {Team1 !== "" ? Team1 : "Robot 1"} Yellow Pixel:{" "}
              </label>
              <select
                id="prop#2"
                className="text-black"
                onChange={(e) => setTeam1AlignSpike(e.target.value)}
              >
                {alignSpike.map((value, key) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
            </div>
            <div className="backdrop2 flex justify-between mb-2" id="2">
              <label htmlFor="prop#2">
                {Team2 !== "" ? Team2 : "Robot 2"} Yellow Pixel:{" "}
              </label>
              <select
                id="prop#2"
                className="text-black"
                onChange={(e) => setTeam2AlignSpike(e.target.value)}
              >
                {alignSpike.map((value, key) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
            </div>
            <div className="backstageCounter flex justify-between items-center">
              <label>Backstage Pixels:</label>
              <Counter value={autoBackstage} changeValue={setAutoBackstage} />
            </div>
            <div className="backdropCounter flex justify-between items-center mb-2">
              <label>Backdrop Pixels:</label>
              <Counter value={autoBackdrop} changeValue={setAutoBackdrop} />
            </div>
            <div className="navigate#1 flex justify-between">
              <label htmlFor="nav#1">
                {Team1 !== "" ? Team1 : "Robot 1"} Navigated:{" "}
              </label>
              <select
                id="nav#1"
                className="text-black"
                onChange={(e) => setTeam1Navigated(e.target.value)}
              >
                {navigated.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
            </div>
            <div className="navigate#2 flex justify-between">
              <label htmlFor="nav#2">
                {Team2 !== "" ? Team2 : "Robot 2"} Navigated:{" "}
              </label>
              <select
                id="nav#2"
                className="text-black"
                onChange={(e) => setTeam2Navigated(e.target.value)}
              >
                {navigated.map((value, key) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="teleop mt-5">
        <div className="title bg-gray-500 flex rounded-md h-12 items-center justify-center mb-2">
          Tele-Op
        </div>
        <div className="TeleopInfo flex justify-center w-[80%] flex-col ml-[10%]">
          <div className="backstage flex justify-between">
            <label>Backstage Pixels</label>
            <Counter value={teleopBackstage} changeValue={setTeleopBackstage} />
          </div>
          <div className="backdrop flex justify-between">
            <label>Backdrop Pixels</label>
            <Counter value={teleopBackdrop} changeValue={setTeleopBackdrop} />
          </div>
          <div className="mosiacs flex justify-between">
            <label>Mosaics</label>
            <Counter value={teleopMosiacs} changeValue={setTeleopMosiacs} />
          </div>
          <div className="setline flex justify-between mb-2">
            <label>Highest Set Line</label>
            <Counter value={teleopSetLine} changeValue={setTeleopSetLine} />
          </div>
        </div>
      </div>
      <div className="endgame">
        <div className="title bg-gray-500 flex rounded-md h-12 items-center justify-center mb-2">
          End Game
        </div>
        <div className="EndgameInfo flex justify-center w-[80%] flex-col ml-[10%]">
          <div className="droneZone#1 flex justify-between">
            <label>{Team1 !== "" ? Team1 : "Robot 1"} Drone Zone</label>
            <select
              onChange={(e) => setTeam1DroneZone(e.target.value)}
              className="text-black"
              name="Drone"
              id="Drone"
            >
              {droneZone.map((value, key) => (
                <option key={key} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="droneZone#2 flex justify-between mb-2">
            <label>{Team2 !== "" ? Team2 : "Robot 2"} Drone Zone</label>
            <select
              onChange={(e) => setTeam2DroneZone(e.target.value)}
              className="text-black"
              name="Drone"
              id="Drone"
            >
              {droneZone.map((value, key) => (
                <option key={key} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="endgameloc#1 flex justify-between">
            <label>{Team1 !== "" ? Team1 : "Robot 1"} Location</label>
            <select
              onChange={(e) => setTeam1EndNavigate(e.target.value)}
              className="text-black"
              name="Drone"
              id="Drone"
            >
              {endNavigate.map((value, key) => (
                <option key={key} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="endgameloc#2 flex justify-between">
            <label>{Team2 !== "" ? Team2 : "Robot 2"} Location</label>
            <select
              onChange={(e) => setTeam2EndNavigate(e.target.value)}
              className="text-black"
              name="Drone"
              id="Drone"
            >
              {endNavigate.map((value, key) => (
                <option key={key} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="fixed bottom-2 flex justify-center w-full z-30">
        <button
          type="submit"
          form="scouter"
          className="bg-blue-600 w-[95%] h-16 rounded-xl text-white"
          // onClick={addMatchInfo}
          onClick={openModal}
        >
          Submit
        </button>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {isSubmitting ? "Submitting..." : "Confirm Submit"}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-700">
                      {isSubmitting
                        ? "Please wait while we submit this match."
                        : "Are you sure you want to submit this match?"}
                    </p>
                  </div>

                  <div className="mt-4 flex justify-end space-x-2">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        setIsOpen(false);
                        setIsSubmitting(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className={`inline-flex ${
                        isSubmitting ? "cursor-default" : "hover:bg-blue-200"
                      } justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900  focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2`}
                      onClick={addMatchInfo}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularProgress size={20} /> : "Submit"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default MatchScout;
