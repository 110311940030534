import { useEffect, useState } from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";
import { Tab } from "@headlessui/react";
import PitScout from "../components/PitScout";
import MatchScout from "../components/MatchScout";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Scoutform() {


  const navigate = useNavigate();

  // useEffect(() => {
  //   if (localStorage.getItem("auth_token") === null) {
  //       navigate("/login")
  //     }
  // });

  let [categories] = useState({
    Pit: [
      {
        id: 1,
        title: "Does drinking coffee make you smarter?",
        date: "5h ago",
        commentCount: 5,
        shareCount: 2,
      },
      {
        id: 2,
        title: "So you've bought coffee... now what?",
        date: "2h ago",
        commentCount: 3,
        shareCount: 2,
      },
    ],
    Match: [
      {
        id: 1,
        title: "Is tech making coffee better or worse?",
        date: "Jan 7",
        commentCount: 29,
        shareCount: 16,
      },
      {
        id: 2,
        title: "The most innovative things happening in coffee",
        date: "Mar 19",
        commentCount: 24,
        shareCount: 12,
      },
    ],
  });

  return (
    <>
      <main className=" h-screen bg-black text-white">
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
        <div className="h-full flex ">
          <Tab.Group>
            <Tab.List className="flex z-20 space-x-1 rounded-xl bg-blue-900/20 p-1 backdrop-blur-md fixed w-full">
              {Object.keys(categories).map((category) => (
                <Tab
                  key={category}
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                      "ring-white/60 ring-offset-2 focus:outline-none",
                      selected
                        ? " bg-yellow-400 text-black shadow"
                        : "text-blue-100 hover:bg-white/[0.12]  hover:text-white"
                    )
                  }
                >
                  {category}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel key={"Pit"}>
                <div className="w-full flex justify-center absolute z-10">
                  <PitScout />
                </div>
              </Tab.Panel>
              <Tab.Panel key={"Match"}>
                <div className="w-full flex justify-center absolute pb-20 bg-black">
                  <MatchScout />
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </main>
    </>
  );
}
