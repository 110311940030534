import React from 'react'
import { IconMinus, IconPlus } from '@tabler/icons-react';

function Counter({value, changeValue}) {

    const handlePlus = () => {
        changeValue(value += 1)
    }

    const handleMinus = () => {
        if(value > 0) {
            changeValue(value -= 1)
        }
    }

  return (
    <>
        <div className="counter flex">
            <div className="decrease">
                <button onClick={handleMinus} className="minus bg-gray-600 rounded-l-sm h-10 w-10 flex justify-center items-center">
                    <IconMinus />
                </button>
            </div>
            <div className="display">
                <input value={value} onChange={e => changeValue(Number(e.target.value))} className=' w-10 h-10 text-black text-center' />
            </div>
            <button onClick={handlePlus} type='button' className='add bg-gray-600 rounded-r-sm h-10 w-10 flex justify-center items-center'>
                <IconPlus />
            </button>
        </div>
    </>
  )
}
export default Counter